import { Controller } from "@hotwired/stimulus"
import mermaid from 'mermaid'

export default class extends Controller {
    static targets = []

    static values = {
        pie: Boolean,
        tokensSankey: Boolean,
        costsSankey: Boolean,
        responseTimesGantt: Boolean,
        responseTimesGanttLastWeek: Boolean,
      }

    initialize() { 
        mermaid.initialize({ startOnLoad: false, securityLevel: 'loose', }) 
    }

    connect() {
        console.log('connect')
    }

    pieValueChanged() {
        if (this.pieValue) {
            mermaid.run({
                nodes: [document.getElementById('costs-pie-mermaid')],
            })        
        }
    }

    tokensSankeyValueChanged() {
        if (this.tokensSankeyValue) {
            mermaid.run({
                nodes: [document.getElementById('tokens-sankey-mermaid')],
            })        
        }
    }

    costsSankeyValueChanged() {
        if (this.costsSankeyValue) {
            mermaid.run({
                nodes: [document.getElementById('costs-sankey-mermaid')],
            })        
        }
    }

    responseTimesGanttValueChanged() {
        if (this.responseTimesGanttValue) {
            mermaid.run({
                nodes: [document.getElementById('response-times-gantt-mermaid')],
            })        
        }
    }

    responseTimesGanttLastWeekValueChanged() {
        if (this.responseTimesGanttLastWeekValue) {
            mermaid.run({
                nodes: [document.getElementById('response-times-gantt-last-week-mermaid')],
            })        
        }
    }
}