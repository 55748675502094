import { Controller } from "@hotwired/stimulus"
import { getAuth, signInWithEmailAndPassword} from "firebase/auth"
import { initializeApp } from "firebase/app"
import { showElems, hideElems, disableElems, enableElems, checkAuthState } from '../helpers.js'

const firebaseConfig = {
  apiKey: "AIzaSyA-RcpNMQ-1Lh5GUdnIaznV4knviyC8xKg",
  authDomain: "luxor-300611.firebaseapp.com",
  projectId: "luxor-300611",
  storageBucket: "luxor-300611.appspot.com",
  messagingSenderId: "940151976235",
  appId: "1:940151976235:web:00fe90cf2e16cfa521156e"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
window.auth = auth

export default class extends Controller {
  static targets = ['username', 'password', 'error', 'button']

  connect() {}

  initialize() {
    // classname is present during load in template.html to allow purgeCss to detect class usage
    this.buttonTarget.classList.remove('cursor-not-allowed')
  }

  doSignIn(event) {
    event.preventDefault()
    hideElems('hidden', this.errorTarget)
    disableElems(this.buttonTarget)

    let whiteSpace = /\s/gi;
    let username = this.usernameTarget.value.replace(whiteSpace, '')

    let password = this.passwordTarget.value

    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        checkAuthState(auth, '/', '/sign_in')
      })
      .catch((error) => {
        enableElems(this.buttonTarget)
        showElems(this.errorTarget)
      })
  }

}