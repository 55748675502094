import { Controller } from "@hotwired/stimulus"
import { enableElems, disableElems, showElems, hideElems, generateContentID } from '../helpers.js'


export default class extends Controller {
    static targets = ['q', 'qSpinner', 'contextDocsTable', 'contextDocsTableRows', 'content', 'rows']

    initialize() { }

    connect() {
        // see https://leastbad.com/stimulus-power-move
        this.element[this.identifier] = this
    }

    getContextDocs(event) {
        event.preventDefault()
        if (event.target == this.qTarget && event.key !== 'Enter') {
            return
        }
        const q = this.qTarget.value?.trim()
        if (!q) {
            return
        }

        disableElems(this.qTarget)
        hideElems('invisible', this.contextDocsTableTarget)
        showElems(this.qSpinnerTarget)
        const url = new URL('/editor/docs', window.location.origin)
        url.searchParams.append('q', q) 
        fetch(url, {
            method: 'GET'
        }).then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error getting context docs.')
            }
        }).then((data) => {
            const rows = this.renderContextDocsRows(data)
            this.contextDocsTableRowsTarget.innerHTML = rows
        })
        .finally(() => {
            enableElems(this.qTarget)
            showElems(this.contextDocsTableTarget)
            hideElems('invisible', this.qSpinnerTarget);
        })
    }

    renderContextDocsRows(data) {
        const docs = data.ctx_docs
        const related = data.related || []
        const rows = []
        docs.forEach((doc, index) => {
            // due to some reason some docs have doc id outside metadata! no idea why!
            const row = `
                <tr 
                    data-editor-doc-id-param="${doc.metadata?.document_id ? doc.metadata?.document_id : doc.document_id}"
                    data-editor-id-param="${doc.id}"
                    data-editor-content-param="${doc.text}"
                    data-editor-score-param="${(doc.score*100.0).toFixed(2)}"
                    data-editor-row-index-param="${index}"
                    data-editor-related-param="${related.join('~')}"
                    data-editor-source-id-param="${doc.metadata?.source_id}"
                    data-editor-target="rows"
                    data-action="click->editor#selectContextDoc click->editor#selectRow"
                    class="hover:bg-indigo-50 hover:cursor-pointer">
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        ${(doc.score*100.0).toFixed(2)}
                        </span>
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">${doc.metadata?.source_id}</td>
                    </td>
                    <td class="relative py-4 pl-3 text-right text-sm font-medium">
                        <span class="mr-1 text-indigo-600 hover:text-indigo-900">Edit</span>
                    </td>
                </tr>
            `
            rows.push(row)
        })
        return rows.join('')
    }

    selectContextDoc(event) {
        event.preventDefault()
        const rightController = document.getElementById('editor-right')['editor-right']
        rightController.contentTarget.value = event.params.content
        rightController.docIdTarget.value = event.params.docId 
        rightController.docIdTarget.id = event.params.id
        rightController.topicTarget.value = this.qTarget.value?.trim()
        rightController.testScoreTarget.innerHTML = event.params.score

        if (event.params.sourceId != 'curator') {
            rightController.saveButtonTarget.disabled = true
            rightController.saveButtonTarget.classList.add('cursor-not-allowed')
        } else {
            rightController.saveButtonTarget.disabled = false
            rightController.saveButtonTarget.classList.remove('cursor-not-allowed')
        }
        
        const related = event.params.related.split('~')
        const relatedPills = []
        related.forEach(rel  => {
            const id = generateContentID(rel)
            relatedPills.push(`<span class="relative mt-2 mr-2 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                <span data-editor-right-target="relScore" data-rel-score-id="${id}" data-rel-topic="${rel}">${rel}</span>
                <div id=${id} class="invisible absolute inline-flex items-center justify-center w-8 h-8 text-sm font-bold text-indigo-700 bg-indigo-50 ring-1 ring-inset ring-indigo-700/10 rounded-full -top-6 -right-2"></div>
            </span>`)             
        })
        rightController.relatedTarget.innerHTML = relatedPills.join('')
    
        rightController.makeTagPills()
        rightController.countTokens()
    }

    selectRow(event) {
        const selectedIndex = parseInt(event.params.rowIndex)
        this.rowsTargets.forEach((row, i) => {
            if (i == selectedIndex) {
                row.classList.add('bg-indigo-100')
            } else {
                row.classList.remove('bg-indigo-100')
            }
        })
    }

}