import { onAuthStateChanged } from "firebase/auth"

function showElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('invisible')
      el.classList.remove('hidden')
    })
}

function hideElems(hideClass='invisible', ...args) {
    args.forEach((el, i) => {
      el.classList.add(hideClass)
    })
}

function disableElems(...args) {
    args.forEach((el, i) => {
      el.classList.add('cursor-not-allowed')
      el.setAttribute('disabled', true)
    })
}

function enableElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('cursor-not-allowed')
      el.removeAttribute('disabled')
    })
}

function validateForm(el) {
  if (!el.checkValidity()) {
    el.reportValidity()
    return
  } 
  return true
}


function checkAuthState(auth, target_uri, startUrl='/sign_in', setCookie=true) {
  onAuthStateChanged(auth, (user) => {  
    if (!user) {
      if (!window.location.href.includes('/sign_')) {
        window.location.href = startUrl
      }
    } else {
      if (!setCookie) {
        if (target_uri) {
          window.location.href = target_uri
        }
        return
      }
      user.getIdToken(false).then((token) => {
        fetch('/sign_in', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({id_token: token})
        }).then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error setting session cookie.')
          }
        }).then((data) => {
          if (data.session) {
            console.log('session set')
            if (target_uri) {
              window.location.href = target_uri
            }
          } else {
            throw new Error('Error setting session cookie.')
          }
        })
      }).catch((error) => {{
        console.log('error setting token', error)
      }})
    }
  })
}

function generateShortID() {
  return Math.random().toString(36).substr(2, 9)
}

function generateContentID(str) {
  // generate an ID from content. Ideally you'd use hashing to generate a unique ID based on the content. Here we are using base 64 as MD5 is not easily available in browser JS.
  const base64str = btoa(str)
  // removing +, / and = from base64str to make it URL safe
  return base64str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}






export { showElems, hideElems, disableElems, enableElems, validateForm, checkAuthState, generateShortID, generateContentID}