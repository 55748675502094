import { Controller } from "@hotwired/stimulus"
import { enableElems, disableElems, showElems, hideElems, generateContentID } from '../helpers.js'
import { Tiktoken } from "js-tiktoken/lite"
import cl100k_base from "js-tiktoken/ranks/cl100k_base"


export default class extends Controller {
    static targets = ['docId', 'topic', 'content', 'tags', 'related', 'testScore', 'relScore', 'tagScore',
                      'testProgressLabel', 'testProgressBar', 'tokenCount', 'saveButton']
    static debounceTimeout = null
    static countTokenDebounceTimeout = null

    initialize() { }

    connect() {
        // see https://leastbad.com/stimulus-power-move
        this.element[this.identifier] = this
    }

    extractSearchTerms(text) {
        const regex = /Search\s*Terms:\s*(.*?)(?:\.|$)/i
        const match = text.match(regex)
        
        if (match && match[1]) {
            return match[1].split(',').map(term => term.trim())
        } else {
            return []
        }
    }

    makeTagPills(){
        const content = this.contentTarget.value
        const tags = this.extractSearchTerms(content)
        const tagPills = []
        tags.forEach(tag  => {
            const id = generateContentID(tag)
            tagPills.push(`<span class="relative mt-2 mr-2 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
            <span data-editor-right-target="tagScore" data-tag-score-id="${id}" data-tag-topic="${tag}">${tag}</span>
            <div id=${id} class="invisible absolute inline-flex items-center justify-center w-8 h-8 text-sm font-bold text-indigo-700 bg-indigo-50 ring-1 ring-inset ring-indigo-700/10 rounded-full -top-6 -right-2"></div>
            </span>`)             
        })
        this.tagsTarget.innerHTML = tagPills.join('')
    }

    updateTagPills(event) {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
            this.makeTagPills()
        }, 2000)
    }

    countTokens() {
        const enc = new Tiktoken(cl100k_base)
        const tokens = enc.encode(this.contentTarget.value)
        this.tokenCountTarget.innerHTML = tokens.length
        if (tokens.length > 8000) {
            this.tokenCountTarget.classList.add('text-white')
            this.tokenCountTarget.classList.add('bg-red-500')
        } else {    
            this.tokenCountTarget.classList.remove('text-white')
            this.tokenCountTarget.classList.remove('bg-red-500')
        }
    }

    updateTokenCount(event) {
        clearTimeout(this.countTokenDebounceTimeout)
        this.countTokenDebounceTimeout = setTimeout(() => {
            this.countTokens()
        }, 2000)
    }

    reloadRightPanel(event) {
        event.preventDefault()
        document.getElementById('right-panel').reload()
    }

    handleScoreSSE(event) {
        event.preventDefault()
        const testBtn = event.target
        testBtn.classList.add('animate-pulse')
        disableElems(testBtn)
        showElems(this.testProgressLabelTarget, this.testProgressBarTarget)
        let totalCount = 1
        let processedCount = 0
        let progressPercent = 0
        this.testProgressLabelTarget.innerHTML = `Test progress ${progressPercent.toFixed(0)}%`
        this.testProgressBarTarget.style.width = `${progressPercent.toFixed(0)}%`
    
        const url = new URL('/editor/scores', window.location.origin)
        url.searchParams.append('doc', this.contentTarget.value)
        url.searchParams.append('topic', this.topicTarget.value)
        url.searchParams.append('score_id', this.testScoreTarget.id)

        this.relScoreTargets.forEach(relScore => {
            if (relScore.dataset.relTopic) {
                url.searchParams.append('topic', relScore.dataset.relTopic)
                url.searchParams.append('score_id', relScore.dataset.relScoreId)
                totalCount += 1
            }
        })

        this.tagScoreTargets.forEach(tagScore => {
            if (tagScore.dataset.tagTopic) {
                url.searchParams.append('topic', tagScore.dataset.tagTopic)
                url.searchParams.append('score_id', tagScore.dataset.tagScoreId)
                totalCount += 1
            }
        })

        const eventSource = new EventSource(url)
    
        eventSource.onopen = (event) => {
            //console.log('Connection opened:', event)
        }
    
        eventSource.onmessage = (event) => {
            //console.log('Message received:', event.data)
        }
    
        eventSource.addEventListener('testScore', (event) => {
            const data = JSON.parse(event.data)
            const targetElement = document.getElementById(data.id)
            if (data.id == 'main-score-id') {
                targetElement.innerHTML = data.score.toFixed(2)
            } else {
                targetElement.innerHTML = data.score.toFixed(0)
            }
            showElems(targetElement)
            processedCount += 1
            progressPercent = (processedCount / totalCount) * 100
            this.testProgressLabelTarget.innerHTML = `Test progress ${progressPercent.toFixed(0)}%`
            this.testProgressBarTarget.style.width = `${progressPercent.toFixed(0)}%`
        })
    
        eventSource.onerror = (event) => {
            eventSource.close()
            testBtn.classList.remove('animate-pulse')
            enableElems(testBtn)
            hideElems('hidden', this.testProgressLabelTarget, this.testProgressBarTarget)
        }
    
        eventSource.addEventListener('streamEnd', (event) => {
            eventSource.close()
            testBtn.classList.remove('animate-pulse')
            enableElems(testBtn)
            hideElems('hidden', this.testProgressLabelTarget, this.testProgressBarTarget)
        })
    }

    saveDoc(event) {
        event.preventDefault()
        disableElems(event.target)
        event.target.innerHTML = `
            Save<div class="absolute -right-2 -top-6 inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-50 text-sm font-bold text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="animate-spin h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div> 
        `
        const docId = this.docIdTarget.value
        const id = this.docIdTarget.id
        const text = this.contentTarget.value
        const tags = this.extractSearchTerms(text)
        const metadata = {
            ...(docId && {document_id: docId}),
            ...(tags && {tags: tags}),
        }
        const payload = {
            ...(id && {id: id}),
            ...(text && {text: text}),
            ...(metadata && {metadata: metadata}),
        }

        fetch('/editor/docs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
        .then((response) => {
            if (response.ok && !response.redirected) {
              return response.json()
            }
            console.log(response)
            throw Error(response.statusText)
          })
          .then((response) => {
            this.docIdTarget.value = response.doc_id
            this.docIdTarget.id = response.id
            event.target.innerHTML = `
                Save<div class="absolute -right-2 -top-6 inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-50 text-sm font-bold text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            `
            setTimeout(() => {
                event.target.innerHTML = 'Save'
            }, 4000)
          }).catch((error) => {
            console.log(error)
            event.target.innerHTML = 'Err. Retry?'
          }).finally(() => {
            enableElems(event.target)
          })

    }

}